<template>
	<div class="cart">
		
		<div class="cartTitle">
			<div class="cartTitleLeft">购物车（{{goodsList.length}}）</div>
			<div class="cartTitleRight" @click="empty"> 删除</div>
		</div>
		<div class="carts">
			<!--购物车有商品-->
			<div class="cartList" v-if="goodsList.length>0" v-for="(item,index) in goodsList">
				<div class="checkBtn flexcenter">
					<span class="nocheck" :value="index" v-bind:class="{'check':item.checked}" @click="selectedProduct(item)"></span>
				</div>
				<div class="cartImg flexcenter">
					<img v-bind:src="item.headimg" width="88px" height="88px">
				</div>
				<div class="cartPro">
					<div class="proname"><span v-html="item.title"></span></div>
					<div class="promoney">{{item.price | filtermoney}}</div>
				</div>
				<div class="cartCount">
					<button type="button" class="btn" @click="subtract(item)">-</button>
					<input type="text" class="inputnum" v-model="item.num">
					<button type="button" class="btn1" @click="add(item)">+</button>
				</div>
			</div>
			<!--购物车无商品-->
			<div class="nocart" v-if="goodsList.length==0">
				<img src="../../public/static/images/nopro.png" style="height: 50%;width: 50%;" />
			</div>
		</div>
		<div class="cartBottom">
			<div class="cartBottomLeft" style="display: flex;">
				<div style="width: 28px;" class="flexcenter">
					<span class="nocheck" v-bind:class="{'check':checkAllFlag}" @click="chooseAll(true)"></span>
				</div>
				<div>全选</div>
			</div>
			<div class="cartBottomRight">
				<div class="totalType">
					<div class="totalAccount">合计：<span class="money">{{totalPrice | filtermoney}}</span></div>
					<div class="text">不含运费</div>
				</div>
				<div class="settle" @click="buy">结算（{{totalCount}}）</div>
			</div>
		</div>
	</div>
</template>

<script>
	import vHeader from '../components/Header.vue'
	export default {
		data() {
			return {
				goodsList: [],
				totalPrice: 0, //总价
				checkAllFlag: false,
				totalCount: 0, //购买数量
			}
		},
		created() {
			this.getCartList();
		},
		filters: {
			filtermoney: function(value) {
				if(!isNaN(value)) {
					value = parseFloat(value)
					return '￥' + value.toFixed(2);
				}

			}
		},
		mounted() {

		},
		methods: {
			//全选
			chooseAll(flag) {
				this.checkAllFlag = flag;
				var self = this;
				this.goodsList.forEach(function(item, index) {
					if(typeof item.checked == 'undefined') { //也要防止未定义
						self.$set(item, "checked", self.checkAllFlag); //通过set来给item添加属性checked
					} else {
						item.checked = self.checkAllFlag;
					}
				});
				this.calcTotalPrice();
			},
			//选择单个商品
			selectedProduct(item) {
				if(typeof item.checked == 'undefined') { //判断是否未定义，如果没点击过按钮是没有注册的，则需要先注册checked属性
					this.$set(item, "checked", true); //局部注册
					item.checked = true;
				} else {
					item.checked = false
				}
				this.calcTotalPrice();
			},
			//减
			subtract(item) {
				if(item.num == 1) {
					$Toast("不能再减少了");
				} else {
					this.$store.dispatch("POST", {
						url: "/api/wx/?action=reducefromcart",
						query: {
							id: item.goodid,
							num: 1
						}
					}).then(result => {
						if(result.data.data > 0) {
							item.num--
							this.calcTotalPrice();
						}
					})
					
				}
			},
			//加
			add(item) {
				var self = this;

				//同步数据库数量
				this.$store.dispatch("POST", {
					url: "/api/wx/?action=addtocart",
					query: {
						id: item.goodid,
						num: 1
					}
				}).then(result => {
					if(result.data.data > 0) {
						item.num++
						this.calcTotalPrice();
					}
				})
				
			},
			// 计算总金额
			calcTotalPrice() {
				var self = this;
				self.totalPrice = 0;
				self.totalCount = 0;
				for(let item of self.goodsList){
					if(item.checked) {
						self.totalCount++;
						self.totalPrice += parseFloat( item.price) * parseInt( item.num);
					}
				}
				
			},
			//删除
			empty() {
				var ids = "";
				this.goodsList.forEach(function(item, index) {
					if(item.checked) { //也要防止未定义
						console.log(item)
						ids += item.cartid + ","
					}
				});

				if(ids != "") {
					this.$store.dispatch("POST", {
						url: "/api/wx/?action=delcart",
						query: {
							goods: ids
						}
					}).then(result => {
						this.getCartList();
					})
				}

			},
			//结算
			buy() {
				var _goodlist = [];
				this.goodsList.forEach(function(item, index) {
					if(item.checked) { //也要防止未定义
						_goodlist.push(item);
					}
				});
				if(_goodlist.length > 0) {
					sessionStorage.setItem("ordergoods", JSON.stringify(_goodlist))
					this.$router.push({
						path: '/cartbuy'
					});
				}else{
					$Toast("没有选择任何商品~")
				}

			},
			//获取购物车
			getCartList() {
				var self = this;
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=cartgood"
				}).then(result => {
					self.goodsList = result.data.data.rows;
					console.log(result.data);
				})
			}
		},
		components: {
			vHeader
		}
	}
</script>

<style scoped="scoped">
	.cart {
		height: 100%;
		width: 100%;
		margin-bottom: 54px;
	}
	
	.cartTitle {
		height: 35px;
		line-height: 35px;
		background: #f6fafe;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
	}
	
	.cartTitleLeft {
		flex: 1;
		font-size: 15px;
		color: #6d9ce5;
		font-weight: bold;
	}
	
	.cartTitleRight {
		width: 37px;
		height: 21px;
		text-align: center;
		line-height: 21px;
		margin-top: 7px;
		border: 1px solid #a4a4a4;
		border-radius: 10px;
	}
	
	.nocart {
		text-align: center;
		padding-top: 50px;
	}
	
	.cartList {
		background: #f6fafe;
		margin-top: 8px;
		height: 95px;
		display: flex;
		padding-left: 15px;
		padding-right: 15px;
	}
	
	.checkBtn {
		width: 28px;
	}
	
	.cartImg {
		width: 88px;
		margin-left: 3px;
	}
	
	.cartPro {
		flex: 1;
		margin-left: 15px;
	}
	
	.proname {
		font-size: 13px;
		color: #7b7b7b;
		margin-top: 12px;
	}
	
	.promoney {
		font-size: 15px;
		color: #26a1ff;
		margin-top: 12px;
	}
	
	.cartCount {
		width: 78px;
		padding-top: 60px;
	}
	
	.btn {
		width: 18px;
		height: 18px;
		border: 1px solid #d2d2d2;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		background: #f6fafe;
	}
	
	.btn1 {
		width: 18px;
		height: 18px;
		border: 1px solid #d2d2d2;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		background: #f6fafe;
	}
	
	.cartBottom {
		height: 52px;
		width: 100%;
		background: #f6fafe;
		position: fixed;
		bottom: 54px;
		border-top: 1px solid #85b1f7;
		padding-left: 20px;
		display: flex;
	}
	
	.cartBottomLeft {
		width: 100px;
		line-height: 52px;
		font-size: 12px;
		color: #979899;
	}
	
	.cartBottomRight {
		flex: 1;
		display: flex;
	}
	
	.totalType {
		flex: 1;
		text-align: right;
	}
	
	.text {
		font-size: 9px;
		color: #979899;
		padding-right: 8px;
	}
	
	.totalAccount {
		font-size: 11px;
		color: #979899;
		margin-top: 11px;
		padding-right: 8px;
	}
	
	.money {
		font-size: 11px;
		color: #d92b2b;
	}
	
	.settle {
		width: 78px;
		margin-right: 40px;
		height: 32px;
		line-height: 32px;
		text-align: center;
		background: #26a1ff;
		color: #FFFFFF;
		font-size: 15px;
		border-radius: 5px;
		margin-top: 10px;
	}
	
	.nocheck.check {
		background: url(../../public/static/images/checked.png);
		width: 20px;
		height: 20px;
		background-size: 100% 100%;
	}
	
	.nocheck {
		background: url(../../public/static/images/nocheck.png);
		width: 20px;
		height: 20px;
		background-size: 100% 100%;
	}
	
	.inputnum {
		width: 40px;
		text-align: center;
		height: 16px;
		background: #f6fafe;
		margin-left: -4px;
		margin-right: -5px;
		border-radius: 0;
		border: 0;
		border-top: 1px solid #d2d2d2;
		border-bottom: 1px solid #d2d2d2;
	}
</style>