<template>
	<div class="header">
		<div  :class="['header',proList.length>0?'headready':'']">
			<div class="headSearch">
				<input type="text" placeholder="搜索产品" v-model="keyword" @focus="ShowSearch" @blur="HideSearch" @input="ChangeText" />
			</div>
			<div class="headCart">
				<img id="cart" src="../../public/static/images/cart.png" width="30" height="27" @click="toCartList" />
				<img id="search" src="../../public/static/images/search.png" @click="Search" style="display: none;opacity: 0;" width="30" height="27" />
			</div>
		</div>

		<div class="searchlist" v-show="proList.length>0">
			<div class="hotList">
				<div class="hotlist_left" v-for="hot in proList" @click="toProDetail(hot.id)">
					<div class="hotimg">
						<img v-bind:src="hot.headimg" />
					</div>
					<div class="proname">{{hot.title}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {

		data() {
			return {
				imgsrc: '',
				nickname: '',
				modal9: false,
				pass: {
					oldpass: '',
					newpass: '',
					checknewpass: ''
				},
				proList: [], //热销列表
				keyword:""
			}
		},
		methods: {
			toCartList() {
				this.$router.push('/cart');
			},
			ShowSearch() {
				$("#cart").animate({
					opacity: 0
				}, 200, "linear", function() {
					$(this).hide();
					$("#search").show().animate({
						opacity: 1
					}, 200, "linear", function() {

					})
				})
			},
			HideSearch() {
				$("#search").animate({
					opacity: 0
				}, 200, "linear", function() {
					$(this).hide();
					$("#cart").show().animate({
						opacity: 1
					}, 200, "linear", function() {

					})
				})
			},
			Search() {
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=searchgood&keyword="+this.keyword
				}).then(result => {
					console.log(result)
					this.proList = result.data.data.rows;
				})
			},
			//查看商品详情
			toProDetail(id) {
				this.$router.push({
					path: '/prodetail',
					query: {
						id: id
					}
				});
			},
			ChangeText(){
				if(this.keyword==""){
					this.proList=[];
				}
			}

		},
		mounted() {
			$(".searchlist").height($(window).height() - 108)
			
		}
	}
</script>

<style scoped>
	.header {
		width: 100%;
		min-height: 54px;
		background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #9dc3fb), color-stop(100%, #84b2f7));
		position: relative;
		z-index: 99;
	}
	.headready{
		position: fixed; width: 100%;
	}
	.header .logo {
		
		margin-left: 30px;
		text-align: center;
	}
	
	.headSearch {
		width: 80%;
		padding-left: 4%;
		float: left;
	}
	
	.headCart {
		width: 16%;
		float: right;
		text-align: center;
		margin-top: 13px;
		
		
	}
	
	.headSearch input {
		height: 30px;
		width: 95%;
		outline: none;
		border-radius: 20px;
		border: none;
		margin-top: 11px;
		padding-left: 14px;
	}
	
	.searchlist {
		width: 100%;
		height: 540px;
		background: #fff;
		position: fixed;
		z-index: 1;
		top: 54px;
		overflow: auto;
	}
	
	.hotList {
		margin-top: 10px;
		padding-left: 2.6%;
	}
	
	.hotlist_left {
		border: 1px solid #bde2ff;
		width: 47.5%;
		margin-right: 1.5%;
		height: 57vw;
		background: #FFFFFF;
		margin-bottom: 10px;
		float: left;
	}
	
	.hotimg {
		text-align: center;
		padding-top: 20px;
	}
	
	.hotimg img {
		width: 40vw;
		height: 40vw;
	}
	
	.proname {
		text-align: center;
		font-size: 13px;
		color: #7b7b7b;
		padding: 0px 5%;
		height: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 90%;
		line-height: 20px;
	}
</style>